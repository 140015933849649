<template>
  <div>
    <t-modal
      @closed="CloseBulkPaymentModal"
      :fixedClasses="fixedClasses"
      :classes="defaultClasses"
      v-model="modalVisible"
      header="Make Invoice Bulk Payment"
    >
      <div class="text-sm p-l-1 pr-2">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            ref="paymentForm"
            class="md:pl-2 md:pr-2"
            @submit.prevent="handleSubmit(submitPaymentForm)"
          >
            <div class="mb-4">
              <label
                class="block md:text-xs text-darkblue"
                for="Customer's name"
              >
                Amount(₦)
              </label>
              <validation-provider
                rules="required|numeric|min_value:1"
                v-slot="{ errors }"
              >
                <input
                  :disabled="loading"
                  type="text"
                  v-model="amount"
                  name="Amount"
                  class="border-2 md:text-xs text-darkblue md:w- w-full border-darkblue rounded-lg block"
                />
                <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="mb-4">
              <label
                class="block md:text-xs text-darkblue"
                for="Customer's name"
              >
                Payment Method(₦)
              </label>
              <validation-provider rules="required" v-slot="{ errors }">
                <select
                  :disabled="loading"
                  v-model="paymentMethod"
                  class="w-full border-2 border-darkblue rounded-lg md:text-xs text-darkblue"
                  name="method"
                  id=""
                >
                  <option value="" selected>Select Method</option>
                  <option value="cash">Cash</option>
                  <option value="cheque">Cheque</option>
                  <option value="transfer">Transfer</option>
                  <option value="p.o.s">P.O.S</option>
                </select>

                <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="mb-4">
              <label
                class="block md:text-xs text-darkblue"
                for="Customer's name"
              >
                Statement Type
              </label>
              <validation-provider rules="required" v-slot="{ errors }">
                <select
                  :disabled="loading"
                  v-model="statementType"
                  class="w-full border-2 border-darkblue rounded-lg md:text-xs text-darkblue"
                  name="statementType"
                  id=""
                >
                  <option value="" selected>Select Statement Type</option>
                  <option value="debit">Debit</option>
                  <option value="credit">Credit</option>
                </select>

                <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="mb-4">
              <label
                class="block md:text-xs text-darkblue"
                for="Customer's name"
              >
                Identifier
              </label>
              <validation-provider rules="custom_text" v-slot="{ errors }">
                <input
                  :disabled="loading"
                  type="text"
                  v-model="identifier"
                  name="Amount"
                  class="border-2 md:text-xs text-darkblue md:w- w-full border-darkblue rounded-lg block"
                />
                <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <template v-slot:footer>
        <div class="flex justify-end">
          <button
            :disabled="loading"
            type="button"
            @click="$refs.paymentForm.requestSubmit()"
            class="bg-darkblue flex justify-between ml-2 rounded-lg text-xs pl-3 pr-3 pt-2 pb-2 text-white"
          >
            <div v-if="loading" class="mr-2">
              <svg
                class="ml-auto mr-auto animate-spin"
                width="18"
                height="18"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M48 25C48 12.2975 37.7025 2 25 2"
                  stroke="black"
                  stroke-width="4"
                />
                <path
                  d="M48 25C48 37.7025 37.7025 48 25 48"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M2 25C2 12.2975 12.2975 2 25 2"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M25 48C12.2975 48 2 37.7025 2 25"
                  stroke="#C9000C"
                  stroke-width="4"
                />
              </svg>
            </div>
            <span> Make Payment </span>
          </button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { modalMixin } from "@/assets/helpers/modalMixin";

  export default {
    name: "PartnerBulkPaymentModal",
    props: {
      ReturnPaymentData: Function,
      CloseBulkPaymentModal: Function,
      loading: Boolean,
      showModal: Boolean,
    },

    data() {
      return {
        modalVisible: false,
        paymentMethod: "",
        amount: null,
        statementType: "",
        identifier: "",
      };
    },
    mixins: [modalMixin],
    computed: {},
    components: { ValidationProvider, ValidationObserver },
    methods: {
      submitPaymentForm() {
        this.ReturnPaymentData({
          method: this.paymentMethod,
          amount: parseInt(this.amount),
          statementType: this.statementType,
          identifier: this.identifier,
        });
      },
    },
    watch: {
      showModal(newValue) {
        this.modalVisible = newValue;
      },
    },
    mounted() {},
    created() {
      this.addModalDefaultClass("wrapper", "w-1/2");
    },
  };
</script>

<style></style>
