<template>
  <div class="relative">
    <h4 class="text-center mt-3">Partner's Profile</h4>
    <div class="p-3 md:p-6 rounded-lg">
      <div class="flex flex-col md:flex-row md:justify-center">
        <div class="bg-darkblue rounded-lg">
          <div class="p-3">
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Name:</span> {{ partner.name }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Email: </span>{{ partner.email }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Phone:</span> {{ partner.phoneNumber }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Address:</span> {{ partner.address }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> No of statements: </span
              >{{ getPartnersStatement.totalCount }}
            </p>
            <div class="mt-2">
              <button
                @click="EditPartner"
                type="button"
                class="bg-green-600 mb-2 hover:bg-lightblue hover:text-black mr-2 md:text-xs text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Edit
              </button>
              <button
                @click="showStatementModal = true"
                type="button"
                class="bg-green-600 mb-2 hover:bg-lightblue hover:text-black mr-2 md:text-xs text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Generate Account Statement
              </button>
              <button
                @click="showBulkPaymentModal = true"
                type="button"
                class="bg-green-600 mb-2 hover:bg-lightblue hover:text-black mr-2 md:text-xs text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Make Payment
              </button>

              <button
                @click="showDeleteModal = true"
                type="button"
                class="bg-red-600 mb-2 md:text-xs hover:bg-lightblue hover:text-black text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Delete
              </button>
            </div>
          </div>
          <div v-if="loading.partner">
            <svg
              class="ml-auto mr-auto animate-spin"
              width="25"
              height="25"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 25C48 12.2975 37.7025 2 25 2"
                stroke="black"
                stroke-width="4"
              />
              <path
                d="M48 25C48 37.7025 37.7025 48 25 48"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M2 25C2 12.2975 12.2975 2 25 2"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M25 48C12.2975 48 2 37.7025 2 25"
                stroke="#C9000C"
                stroke-width="4"
              />
            </svg>
          </div>
        </div>
        <div>
          <div class="overflow-auto md:overflow-visible mt-4 md:mt-0">
            <h4 class="text-center mt-4 block md:hidden">Statements</h4>

            <t-table
              id="my-table"
              :classes="table_classes"
              :headers="table_header"
              :data="table_data"
            >
              <template slot="column" slot-scope="props">
                <td
                  @click="NavigateToSingleStatement(props)"
                  :class="props.tdClass"
                >
                  {{ props.text }}
                </td>
              </template>
            </t-table>
          </div>
          <div>
            <transition name="slide-fade">
              <NotificationModal
                v-if="showDeleteModal"
                :modalData="modalData"
                @modal-success="DeletePartner($event)"
                @modal-close="CloseModal($event)"
              />
            </transition>
          </div>
          <div v-if="loading.search" class="flex justify-center flex-col">
            <div>
              <p class="text-red-700 text-center md:text-xs font-bold">
                Loading,please wait...
              </p>
            </div>
            <div class="mt-2">
              <svg
                class="ml-auto mr-auto animate-spin"
                width="25"
                height="25"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M48 25C48 12.2975 37.7025 2 25 2"
                  stroke="black"
                  stroke-width="4"
                />
                <path
                  d="M48 25C48 37.7025 37.7025 48 25 48"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M2 25C2 12.2975 12.2975 2 25 2"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M25 48C12.2975 48 2 37.7025 2 25"
                  stroke="#C9000C"
                  stroke-width="4"
                />
              </svg>
            </div>
          </div>

          <div
            v-if="
              getPartnersStatement.result.length < 1 && loading.search != true
            "
            class="mb-6 mt-2"
          >
            <p class="text-center md:text-xs">
              No statements found for this partner
            </p>
          </div>
          <div class="mb-6 mt-2 text-center">
            <button
              :class="
                getPartnersStatement.hasPrevious != true
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              "
              :disabled="
                getPartnersStatement.hasPrevious != true ||
                loading.search != false
              "
              @click="Navigate('prev')"
              class="text-darkblue md:text-xs hover:text-blue-600 font-bold"
            >
              Prev
            </button>

            <span class="ml-2 mr-2 md:text-xs"
              >{{ currentPage }}/{{ number_of_pages }}</span
            >
            <button
              :class="
                getPartnersStatement.hasMore != true
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              "
              :disabled="
                getPartnersStatement.hasMore != true || loading.search != false
              "
              @click="Navigate('next')"
              class="text-darkblue md:text-xs hover:text-blue-600 font-bold"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <t-modal
        :fixedClasses="fixedClasses"
        :classes="defaultClasses"
        v-model="showStatementModal"
        header="Generate Partner's statement"
      >
        <div class="text-sm p-l-1 pr-2">
          <h6 class="text-sm mb-3">
            Select the date range below and the statement will be sent to your
            mail
          </h6>
          <div class=" ">
            <t-datepicker
              :classes="datePickerDefaultClass"
              :dateFormat="'Y-m-d H:i:S'"
              :userFormat="'Y-m-d'"
              :placeholder="'Select date range'"
              :range="true"
              v-model="selectedDate"
            />
          </div>
        </div>
        <template v-slot:footer>
          <div class="flex justify-between">
            <button
              @click="showStatementModal = false"
              class="text-red-700 mr-2 text-xs pl-3 pr-3 pt-1 pb-1"
              type="button"
            >
              Cancel
            </button>
            <button
              type="button"
              @click="PrintStatementPdf"
              class="bg-darkblue flex justify-between ml-2 rounded-lg text-xs pl-3 pr-3 pt-2 pb-2 text-white"
            >
              <div v-if="loading.statementPdf" class="mr-2">
                <svg
                  class="ml-auto mr-auto animate-spin"
                  width="18"
                  height="18"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M48 25C48 12.2975 37.7025 2 25 2"
                    stroke="black"
                    stroke-width="4"
                  />
                  <path
                    d="M48 25C48 37.7025 37.7025 48 25 48"
                    stroke="white"
                    stroke-width="4"
                  />
                  <path
                    d="M2 25C2 12.2975 12.2975 2 25 2"
                    stroke="white"
                    stroke-width="4"
                  />
                  <path
                    d="M25 48C12.2975 48 2 37.7025 2 25"
                    stroke="#C9000C"
                    stroke-width="4"
                  />
                </svg>
              </div>
              <span> Generate Statement </span>
            </button>
          </div>
        </template>
      </t-modal>
    </div>
    <div class="mt-3 p-3 md:p-6">
      <div class="overflow-auto md:overflow-visible md:mt-0">
        <h5 class="text-darkblue text-center">Partner Account Statement</h5>
        <t-table
          id="my-table"
          :classes="tableClasses"
          :headers="tableHeader"
          :data="tableData"
        >
        </t-table>
      </div>
    </div>
    <div>
      <PartnerBulkPaymentModal
        :CloseBulkPaymentModal="CloseBulkPaymentModal"
        :showModal="showBulkPaymentModal"
        :loading="loading.bulkPayment"
        :ReturnPaymentData="SetPaymentData"
      />
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import NotificationModal from '../../components/NotificationModal.vue';
  import { tableMixin } from '@/assets/helpers/tableMixin';
  import { modalMixin } from '@/assets/helpers/modalMixin';
  import { datePickerMixin } from '@/assets/helpers/datePickerMixin';

  import { permissionMixin } from '@/assets/helpers/permissionMixin';
  import PartnerBulkPaymentModal from '../../components/PartnerBulkPaymentModal.vue';

  import { mapMutations } from 'vuex';

  export default {
    name: 'SinglePartner',
    props: {},
    NotificationModal,
    mixins: [tableMixin, permissionMixin, datePickerMixin, modalMixin],

    components: { NotificationModal, PartnerBulkPaymentModal },
    apollo: {
      getPartnersStatement: {
        query: gql`
          query getPartnersStatement($query: PartnersStatementInput!) {
            getPartnersStatement(query: $query) {
              result {
                _id
                serialId
                authorId {
                  _id
                  name
                }
                type

                statementBalance
                statementTotal
                createdAt
              }
              totalCount
              hasMore
              hasPrevious
            }
          }
        `,
        variables() {
          return {
            query: {
              cursor: Number(this.searchCursor),
              limit: this.searchData.limit,
              type: this.searchType,
              partnerId: this.$route.params.id,
            },
          };
        },
        error(error) {
          this.$emit('show-alert', {
            type: 'error',
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.search = true;
            return;
          }

          this.loading.search = false;
        },
      },
      getPartnersAccountStatement: {
        query: gql`
          query getPartnersAccountStatement($partnerId: ID!) {
            getPartnersAccountStatement(partnerId: $partnerId) {
              debitStatementTotal
              creditStatementTotal
              creditStatementBalance
              debitStatementBalance
            }
          }
        `,
        variables() {
          return {
            partnerId: this.$route.params.id,
          };
        },
        error(error) {
          this.$emit('show-alert', {
            type: 'error',
            message: error.message,
          });
        },
        skip() {
          if (this.appPermissions[`statement`]) {
            return this.appPermissions[`statement`]['read'] == false;
          }
          return true;
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.accountStatement = true;
            return;
          }

          this.loading.accountStatement = false;
        },
      },
      partner: {
        query: gql`
          query partner($id: ID!) {
            partner(id: $id) {
              name
              phoneNumber
              address
              email
              previousCreditBalance
              previousDebitBalance
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        error(error) {
          this.$emit('show-alert', {
            type: 'error',
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.partner = true;
            return;
          }

          this.loading.partner = false;
        },
      },
      getPartnersPrintableStatement: {
        query: gql`
          query getPartnersPrintableStatement(
            $query: PartnersPrintableStatementInput!
          ) {
            getPartnersPrintableStatement(query: $query) {
              status
            }
          }
        `,
        variables() {
          return {
            query: {
              partnerId: this.$route.params.id,
              dateFilter: this.formattedDateFilter,
            },
          };
        },
        skip() {
          return this.skipStatementQuery;
        },
        error(error) {
          this.$emit('show-alert', {
            type: 'error',
            message: error.message,
          });
        },
        result({ loading, errors }) {
          if (!loading && !errors) {
            this.$emit('show-alert', {
              type: 'success',
              message:
                "Your statement will be generated and sent to your mail when it's ready",
            });
          }
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.statementPdf = true;
            return;
          }

          this.showStatementModal = false;
          this.loading.statementPdf = false;
        },
      },
    },
    data() {
      return {
        showDeleteModal: false,
        showStatementModal: false,
        selectedDate: [],
        paymentData: {},
        tableHeader: [
          {
            id: '',
            value: 'analysis',
            text: 'Analysis.',
            className: '',
          },
          {
            value: 'amount',
            text: 'Amount(₦)',
            className: 'fortunate-table',
          },
        ],
        formattedDateFilter: {
          from: '',
          to: '',
        },
        tableData: [],
        statementPaymentType: '',
        showBulkPaymentModal: false,

        modalData: {
          title: 'Delete partner',
          message:
            "Are you sure you want to delete this partner? You can't get it back if you continue.",
          cancelText: 'Cancel',
          successText: 'Delete',
          loading: false,
        },
        searchData: {
          limit: 5,
          customerId: '',
          cursor: 0,
        },
        searchType: 'GT',
        loading: {
          search: false,
          partner: false,
          statementPdf: false,
          accountStatement: false,
          bulkPayment: false,
        },
        searchCursor: 0,
        search: {
          result: [],
          hasMore: false,
          hasPrevious: false,
        },
        partner: {
          previousDebitBalance: 0,
          previousCreditBalance: 0,
        },
        getPartnersAccountStatement: {
          debitStatementTotal: 0,
          creditStatementTotal: 0,
          creditStatementBalance: 0,
          debitStatementBalance: 0,
        },
        getPartnersStatement: {
          result: [],
          hasMore: false,
          hasPrevious: false,
          totalCount: 0,
        },
        currentPage: 1,
        table_header: [
          {
            value: 'serialId',
            text: 'No',
            className: '',
          },

          {
            value: 'type',
            text: 'Type',
            className: '',
          },
          {
            value: 'statementTotal',
            text: 'Total(₦)',
            className: 'fortunate-table',
          },
          {
            value: 'statementBalance',
            text: 'Balance(₦)',
            className: 'fortunate-table',
          },
          {
            value: 'status',
            text: 'Payment Status',
            className: 'fortunate-table',
          },

          {
            value: 'regDate',
            text: 'Reg. Date',
          },
        ],
        table_data: [],
        getPartnersPrintableStatement: '',

        table_dropdown_class: {
          button:
            'block font-black break-normal px-4 py-2 text-black transition duration-100 ease-in-out bg-transparent border border-transparent rounded  hover:text-green-400 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
          wrapper: 'inline-flex flex-col',
          dropdownWrapper: 'relative z-10',
          dropdown:
            'origin-top-left absolute left-0 w-32 rounded shadow bg-white mt-1',
          enterClass: '',
          enterActiveClass:
            'transition ease-out duration-100 transform opacity-0 scale-95',
          enterToClass: 'transform opacity-100 scale-100',
          leaveClass: 'transition ease-in transform opacity-100 scale-100',
          leaveActiveClass: '',
          leaveToClass: 'transform opacity-0 scale-95 duration-75',
        },
        skipStatementQuery: true,
        table_classes: {
          table:
            'min-w-full  text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-gray-200 border',
          thead: 'border-b-2 md:text-xs border-darkblue ',
          theadTr: '',
          theadTh:
            'px-3 py-2 font-bold md:text-center text-left bg-transparent ',
          tbody: 'bg-white divide-y divide-gray-100',
          tr: 'cursor-pointer hover:bg-dashboardbody  rounded-lg text-xs',
          td: 'px-3 py-2  whitespace-no-wrap',
          tfoot: '',
          tfootTr: '',
          tfootTd: '',
        },

        icons: {
          pen: require('@/assets/imgs/icons/white-pen.svg'),
        },
      };
    },
    computed: {
      number_of_pages: function () {
        return Math.ceil(
          Number(this.getPartnersStatement.totalCount) /
            Number(this.searchData.limit)
        );
      },
    },
    methods: {
      ...mapMutations(['setBreadcrumbs']),
      CloseBulkPaymentModal() {
        this.showBulkPaymentModal = false;
      },
      async SetPaymentData(paymentData) {
        const { method, amount, identifier, statementType } = paymentData;

        this.paymentData = {
          method,
          amount,
          identifier,
        };
        this.statementPaymentType = statementType;
        await this.MakeStatementBulkPayment();
      },
      async MakeStatementBulkPayment() {
        this.loading.bulkPayment = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation makeBulkStatementPayment(
                $partnerId: ID!
                $payment: PaymentInput!
                $type: StatementType!
              ) {
                makeBulkStatementPayment(
                  partnerId: $partnerId
                  payment: $payment
                  type: $type
                ) {
                  statementId
                  serialId
                  amount
                }
              }
            `,
            // Parameters
            variables: {
              partnerId: this.$route.params.id,
              payment: this.paymentData,
              type: this.statementPaymentType,
            },
          });

          this.showBulkPaymentModal = false;

          this.loading.bulkPayment = false;

          if (!errors && !!data) {
            const messages = data.makeBulkStatementPayment.map((payment) => {
              return `Statement No: ${
                payment.serialId
              }, Amount(₦): ${payment.amount.toLocaleString()}`;
            });
            this.paymentData = {};

            this.$emit('show-alert', {
              type: 'success',
              message: messages,
              title: 'Payment made successfully for the following statements',
            });

            this.$apollo.queries.getPartnersAccountStatement.refetch();
            this.$forceUpdate();

            return;
          }

          if (!!errors && data == null) {
            return this.$emit('show-alert', {
              type: 'error',
              message: errors[0].message,
            });
          }

          this.$emit('show-alert', {
            type: 'error',
            message: `Error making bulk payment ,check your network or contact your developer`,
          });
        } catch (error) {
          this.showBulkPaymentModal = false;

          this.loading.bulkPayment = false;

          this.$emit('show-alert', {
            type: 'error',
            message: `Error making bulk payment ,check your network or contact your developer`,
          });
        }
      },
      async PrintStatementPdf() {
        if (this.selectedDate.length > 0) {
          if (this.selectedDate.length > 1) {
            this.formattedDateFilter = {
              from: new Date(this.selectedDate[0]).getTime(),
              to: new Date(this.selectedDate[1]).getTime(),
            };
            if (!this.skipStatementQuery) {
              this.$apollo.queries.getPartnersPrintableStatement.refetch();
              return;
            }
            this.skipStatementQuery = false;
            return;
          }
          this.formattedDateFilter = {
            from: new Date(this.selectedDate[0]).getTime(),
            to: new Date(this.selectedDate[0]).getTime(),
          };
          if (!this.skipStatementQuery) {
            this.$apollo.queries.getPartnersPrintableStatement.refetch();
            return;
          }
          this.skipStatementQuery = false;
        } else {
          return this.$emit('show-alert', {
            type: 'error',
            message: 'Select a date range before you can perform this action',
          });
        }
      },
      NavigateToSingleStatement(row) {
        this.$router.push({
          name: 'singleStatement',
          params: { id: this.getPartnersStatement.result[row.rowIndex]._id },
        });
      },
      async DeletePartner() {
        this.modalData.loading = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation ($id: ID!) {
                deletePartner(id: $id) {
                  _id
                  serialId
                  isDeleted
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
            },
          });

          if (!errors && !!data.deletePartner._id) {
            this.$emit('show-alert', {
              type: 'success',
              message: `Partner deleted successfully`,
            });

            return this.$router.push('/app/partners');
          }
          this.modalData.loading = false;

          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == 'BAD_USER_INPUT';
            });
            return this.$emit('show-alert', {
              type: 'error',
              message: errorResponse.message,
            });
          }

          this.$emit('show-alert', {
            type: 'error',
            message: `Error deleting partner,check your network or contact your developer`,
          });
        } catch (error) {
          this.modalData.loading = false;

          this.$emit('show-alert', {
            type: 'error',
            message: `Error deleting partner,check your network or contact your developer`,
          });
        }
      },
      CloseModal() {
        this.showDeleteModal = false;
        this.modalData.loading = false;
      },
      Navigate(type) {
        switch (type) {
          case 'prev':
            this.searchType = 'LT';
            this.currentPage--;

            this.searchCursor = this.getPartnersStatement.result[0].serialId;
            break;

          case 'next':
            this.searchType = 'GT';
            this.currentPage++;
            this.searchCursor =
              this.getPartnersStatement.result[
                this.getPartnersStatement.result.length - 1
              ].serialId;
            break;

          default:
            break;
        }
      },
      TableButton() {},
      EditPartner() {
        this.$router.push({
          name: 'managePartner',
          params: { mode: 'edit', id: this.$route.params.id },
        });
      },
      FormatTableData(data) {
        this.table_data = data.map((statement) => {
          const date = new Date(Number(statement.createdAt));
          return {
            id: statement._id,
            serialId: statement.serialId,
            type: statement.type,
            status: statement.statementBalance > 0 ? 'pending' : 'paid',
            statementTotal: statement.statementTotal.toLocaleString(),
            statementBalance: statement.statementBalance.toLocaleString(),
            regDate: `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`,
            btn: 'btn',
          };
        });
      },
      ToggleChartSpinner() {
        this.chart_loading = !this.chart_loading;
      },
    },
    watch: {
      partner: {
        deep: true,
        handler() {
          this.tableData = [
            ...this.tableData,
            {
              analysis: ' Previous Debit Balance',
              amount: this.partner.previousDebitBalance
                ? this.partner.previousDebitBalance.toLocaleString()
                : 0,
            },
            {
              analysis: ' Previous Credit Balance',
              amount: this.partner.previousCreditBalance
                ? this.partner.previousCreditBalance.toLocaleString()
                : 0,
            },
          ];
        },
      },
      getPartnersStatement: {
        deep: true,
        handler(newValue) {
          this.FormatTableData(newValue.result);
        },
      },
      getPartnersAccountStatement: {
        deep: true,
        handler(accountStatement) {
          this.tableData = [
            {
              analysis: ' Debit Total',
              amount: accountStatement.debitStatementTotal.toLocaleString(),
            },
            {
              analysis: ' Debit Balance',
              amount: accountStatement.debitStatementBalance.toLocaleString(),
            },
            {
              analysis: ' Credit Balance',
              amount: accountStatement.creditStatementBalance.toLocaleString(),
            },
            {
              analysis: ' Credit Total',
              amount: accountStatement.creditStatementTotal.toLocaleString(),
            },
          ];
        },
      },
    },
    mounted() {
      this.$apollo.queries.partner.setOptions({
        fetchPolicy: 'network-only',
      });
      this.$apollo.queries.getPartnersAccountStatement.setOptions({
        fetchPolicy: 'network-only',
      });

      this.setBreadcrumbs([
        { displayName: 'Partners', pathName: 'partners' },

        {
          displayName: 'Single Partner',
          pathName: 'singlePartner',
          params: { id: this.$route.params.id },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
